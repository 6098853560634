<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" class="whitespace-no-wrap">
    <vx-tooltip class="float-left" text="Ver Detalle" v-show="this.params.data.payments ? this.params.data.payments.length > 0 : this.params.data.id > 0">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer stroke-current" class="ml-2" @click="clickHandler()" />
    </vx-tooltip>
    <vx-tooltip class="float-left" text="Cargar Pago" v-show="this.params.data.total_after_tax > 0" v-if="this.params.data.payments">
      <feather-icon icon="DollarSignIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer stroke-current" @click="confirmSubmit()" />
    </vx-tooltip>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererActionsDirectPaymentPendingRequests',
        methods: {
          // openSidebar() {
          //   this.params.context.componentParent.sidebarData = this.params.data;
          //   this.params.context.componentParent.paymentsSidebarActive = true;
          // },
          clickHandler() {
            this.params.showPopup(this.params.data)
          },
          confirmSubmit() {
            this.$vs.dialog({
              type: "confirm",
              color: "warning",
              title: `Confirmar Solicitud`,
              text: `¿Estás seguro que quieres realizar esta solicitud?`,
              accept: this.submitData,
              acceptText: "Aceptar",
            });
          },
          submitData() {
            this.$store.dispatch("directPaymentModule/addDirectPaymentCashOutRequest")
            .then((response) => {
              if(response.status){
                this.showSuccess();
                this.$router.push("/direct-payment/requests")
              }
            })
            .catch((error) => {
              this.showError(error);
            });
          },
          showSuccess() {
            this.$vs.notify({
              time: 8000,
              color: "success",
              title: "Solicitud enviada",
              text: "Se ha enviado correctamente la solicitud",
              position: 'top-center',
              iconPack: 'feather',
              icon:'icon-check',
            });
          },
          showError(msg) {
            this.$vs.notify({
              time: 8000,
              color: "danger",
              title: "ERROR",
              text: `${msg}`,
              position: 'top-center',
              iconPack: 'feather',
              icon:'icon-alert-circle',
            });
          },
        },
    }
</script>
