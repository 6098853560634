<template>
  <vs-popup fullscreen :title="title" :active.sync="popupActiveLocal">
      <ag-grid-vue
          ref="popupDetails"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="items"
          colResizeDefault="shift"
          :animateRows="true"
          :pagination="false"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl">
      </ag-grid-vue>
  </vs-popup>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
  props: {
      data: {
          type: Array,
          required: true
      },
      popupActive: {
          type: Boolean,
          required: true
      },
      title: {
          type: String,
          required: true
      }
  },
  data() {
      return {
          gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: true,
          },
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true
          },
          columnDefs: [
              {
                headerName: 'ID',
                field: 'id',
                filter: true,
                width: 120,
                // hide: this.$acl.check('school')
              },
              {
                  headerName: "Acudiente",
                  field: "guardian.name",
                  width: 240,
                  filter: true,
                  cellClass: "cellMayusc",
              },
              {
                  headerName: 'Fecha de pago',
                  field: 'payment_date',
                  filter: false,
                  width: 150,
              },
              {
                headerName: 'Monto',
                field: 'amount',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.amount, '$ '),
              },
              {
                headerName: 'Motivo',
                field: 'reason',
                filter: true,
              },
          ],
          // popupActiveLocal: JSON.parse(this.popupActive)
      }
  },
  components: {
      AgGridVue,
  },
  computed: {
      popupActiveLocal: {
          get() {
              return JSON.parse(this.popupActive)
          },
          set() {
              this.$emit('updatePopupActive')
          }
      },
      items() {
          return this.data
      }
  },
}
</script>
